import { styled } from "@mui/material";

export const StyledWrapper = styled('div')`
    width: 100%;
    padding: 8px;
`;
export const StyledContainer = styled('div')`
    background-color: #202020;
    border-radius: 8px;
    padding: 16px;
`;
export const StyledText = styled('div')`
    color: #fff;
    line-height: 24px;
    margin-bottom: 8px;
`;