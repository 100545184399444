import { FC, useMemo } from "react";
import { StyledContainer, StyledText, StyledWrapper } from "./SearchCounter.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useMe } from "@/providers/MeProvider/Me.provider";
import { useRouter } from "@/providers/Router/Router.provider";

export const SearchCounter: FC = () => {
  const { user, searchLimits, invitedUsers, confirmedInvitedUsers } = useMe();
  const router = useRouter();
    const isFreePlan = user?.paymentPlan.name === 'INDIVIDUAL' || user?.paymentPlan.name === 'PENDING';
    const amountOfPaidUsers = useMemo(() => {
        return invitedUsers.length + confirmedInvitedUsers.length + 1;
    }, [invitedUsers, confirmedInvitedUsers]);
    
    if (!isFreePlan) {
        return null;
    }
    return <StyledWrapper>
        <StyledContainer>
            <StyledText>{searchLimits.available}/{searchLimits.limit} free searches left</StyledText>
            <Button variant="secondary" onClick={() => {
                router.push({
                    name: router.name,
                    params: router.params,
                    query: {
                        ...router.query,
                        dialog: 'payment',
                        plan: amountOfPaidUsers > 1 ? 'TEAM' : 'PROFESSIONAL',
                    }
                })
            }}>Upgrade now</Button>
        </StyledContainer>
    </StyledWrapper>;
};