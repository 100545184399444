import { useEffect } from "react";
import { useMe } from "providers/MeProvider/Me.provider";
import { StyledButtonContainter, StyledButtonWrapper, StyledCard, StyledContainer, StyledText, StyledWrapper } from "./NoApps.styled";
import { Loader } from "@/components/Loader/Loader.component";
import { StyledTitle } from "../../Login/Login.styled";
import { Button } from "@/components/v3/Fields/Button/Button.component";
import { useRouter } from "@/providers/Router/Router.provider";
import { HttpClient } from "@/axios/HttpClient";
import { envUrl } from "@/service/helpers";

export const NoAppsPage = () => {
    const { loadUser, user, isIntegrationInProgress } = useMe();
    const router = useRouter();

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         loadUser();
    //     }, 3000)

    //     if (!isIntegrationInProgress) {
    //         router.push({
    //             name: 'forYou'
    //         })
    //     }
    //     return () => {
    //         clearInterval(interval)
    //     }
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isIntegrationInProgress])
    const startHandler = async () => {
        await HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
            onboarded: true,
          }).then(() => {
            loadUser();
          });
    }
    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledTitle>No apps connected</StyledTitle>
                <StyledCard>
                    <StyledText>
                        Sense is designed to automatically organised your work and tell you about everything happened with you projects and people. Without connected apps you will not be able to get full power of the product. 
                    </StyledText>
                    <StyledButtonContainter>
                        <StyledButtonWrapper>
                            <Button
                                onClick={() => router.push({
                                    name: 'manageIntegrations'
                                })}
                            >Back to connect apps</Button>
                            {/* <Button
                                variant="secondary"
                                onClick={() => startHandler()}
                            >Start exploring product</Button> */}
                        </StyledButtonWrapper>
                    </StyledButtonContainter>
                </StyledCard>
            </StyledWrapper>
        </StyledContainer>
    )
}