import { StyledActionsContainer, StyledActionsStepContainer, StyledAppsWrapper, StyledSectionHeader } from './ConnectApps.styled';
import { Button } from '@/components/v3/Fields/Button/Button.component';
import { ManageAppsLayout } from '@/components/v3/Layouts/ManageAppsLayout/ManageAppsLayout.component';
import { Integration } from '@/components/v3/Modals/ManageIntegrationsDialog/components/ManageIntegrationsContent/components/Integration/Integration.component';
import { useMe } from '@/providers/MeProvider/Me.provider';
import { useConfirm } from '@/hooks/v3/UseConfirm/UseConfirm.hook';
import { envUrl } from '@/service/helpers';
import { useOnboardingPage } from '@/providers/v3/OnboardingPage/OnboardingPage.provider';
import { useRouter } from '@/providers/Router/Router.provider';
import { FC, useEffect, useMemo, useState } from 'react';
import { AppTypes } from '@/types/AppTypes/AppTypes.type';
import { HttpClient } from '@/axios/HttpClient';
import { ToastService } from '@/service/ToastService';
import { generateAppNameFromType } from '@/components/v3/Modals/ManageIntegrationsDialog/components/ManageIntegrationsContent/ManageIntegrationsContent.utils';
import { useChannels } from '@/providers/v3/Channels/Channels.provider';
import { LoaderLayout } from '@/components/v3/Layouts/LoaderLayout/LoaderLayout.component';
import { useModal } from '@/store/ModalContext';
import { StartIntegrationDialog } from '@/components/v3/Modals/StartIntegrationDialog/StartIntegrationDialog.component';
import { IntegrationType } from '@/components/v3/Modals/ConnectChannels/ConnectChannels.type';
import { ConnectChannels } from '@/components/v3/Modals/ConnectChannels/ConnectChannels.component';

export const ConnectApps: FC = () => {
  const [isStartIntegrationModalOpen, setIsStartIntegrationModalOpen] = useState<boolean>(false);
const router = useRouter();
useEffect(() => {
  if (!!router.query.type) {
    setIsStartIntegrationModalOpen(true);
  }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [router]);
  const [isOpenConnectChannels, setIsOpenConnectChannels] = useState<IntegrationType | false>(false);
  const { user, loadUser } = useMe();
  const { selectedAllApps, progress, previousStep } = useOnboardingPage();
  const { update: updateChannels } = useChannels();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isAnyAppConnected = useMemo(() => {
    if (!user?.integrations) {
      return false;
    }
    return Object.values(user.integrations).some(integration => integration.active);
  }, [user?.integrations]);
  // const isAllAppsConnected = useMemo(() => {
  //   const appIntegrationMap = {
  //     [AppTypes.GoogleDrive]: user?.integrations?.google_drive?.active,
  //     [AppTypes.Gmail]: user?.integrations?.gmail?.active,
  //     [AppTypes.Slack]: user?.integrations?.slack?.active,
  //     [AppTypes.MicrosoftTeams]: user?.integrations?.microsoft_teams?.active,
  //     [AppTypes.Outlook]: user?.integrations?.outlook?.active,
  //     [AppTypes.GoogleCalendar]: user?.integrations?.google_calendar?.active,
  //     [AppTypes.Notion]: user?.integrations?.notion?.active,
  //     [AppTypes.Jira]: user?.integrations?.atlassian?.active,
  //     [AppTypes.Asana]: user?.integrations?.asana?.active,
  //     [AppTypes.Github]: user?.integrations?.github?.active,
  //     [AppTypes.ClickUp]: user?.integrations?.click_up?.active,
  //     [AppTypes.Trello]: user?.integrations?.trello?.active,
  //     [AppTypes.Figma]: user?.integrations?.figma?.active,
  //     [AppTypes.Dropbox]: user?.integrations?.dropbox_business?.active,
  //     [AppTypes.Sharepoint]: user?.integrations?.microsoft_teams?.active, // Перевір чи правильно це відображено
  //     [AppTypes.TeamSpaces]: user?.integrations?.microsoft_teams?.active, // Те саме тут
  //     [AppTypes.Confluence]: user?.integrations?.atlassian?.active
  //   };
  //   return selectedAllApps.every(app => appIntegrationMap[app] === true);
  // }, [selectedAllApps, user?.integrations?.asana?.active, user?.integrations?.atlassian?.active, user?.integrations?.click_up?.active, user?.integrations?.dropbox_business?.active, user?.integrations?.figma?.active, user?.integrations?.github?.active, user?.integrations?.gmail?.active, user?.integrations?.google_calendar?.active, user?.integrations?.google_drive?.active, user?.integrations?.microsoft_teams?.active, user?.integrations?.notion?.active, user?.integrations?.outlook?.active, user?.integrations?.slack?.active, user?.integrations?.trello?.active]);


  const { component, confirm } = useConfirm({
    title: 'Disconnect integration',
    content: <>
        <StyledSectionHeader title="Are you sure you want to disconnect this integration?" />
        <div>
            Synchronisation of all the resources and updates from this app will be stopped for all users. The data that was already synchronised will remain. You can delete all integrations data using the block at the bottom of the “Manage integrations” modal. 
        </div>
    </>,
    submitButtonProps: {
        children: 'Disconnect',
        variant: 'error'
    },
    cancelButtonProps: {
        children: 'Cancel',
        variant: 'primary'
    }
  });
  const activeIntegrations = useMemo(() => {
    if (!user?.integrations) {
      return [];
    }
    return Object.entries(user.integrations).filter(([key, value]) => value.active).map(([key, value]) => key);
  }, [user?.integrations]);
  console.log('activeIntegrations', activeIntegrations);
  
  const startIntegration = async () => {
    setIsLoading(true);
    if (isAnyAppConnected) {
      try {
        const promises = activeIntegrations.map(integration =>
          HttpClient.get(`${envUrl}/sense-facade/v1/integrations/synchronization?userId=${user?.id}&type=${integration}`)
        );
      
        await Promise.all(promises);
        // Дії після завершення всіх запитів
      } catch (error) {
        ToastService.showToast('error', 'Some error occurred! Please try again later!');
      }
      await HttpClient.patch(`${envUrl}/organization-service/v1/users/${user?.id}`, {
        onboarded: true,
      }).then(() => {
        loadUser();
        router.push({
          name: 'progressPage'
        })
      });
    } else {
      router.push({
        name: 'noAppsPage'
      });
    }
    setIsLoading(false);
  }
  const disconnectHandler = (type: string | undefined, email?: string) => {
      if (!user?.isAdmin) {
        return;
      }
      confirm(async (
        result
      ) => {
        if (result) {
          setIsLoading(true);
          let url = `${envUrl}/sense-facade/v1/integrations?userId=${user?.id}&type=${type}`;
          if (email) {
            url += `&email=${email}`;
          }
          await HttpClient.delete(url)
            .then(response => response.data)
            .then(data => {
              HttpClient.get(`${envUrl}/sense-facade/v1/users/${user?.id}/info`)
                .then(response => response.data)
                .then(async data => {
                  updateChannels();
                  await loadUser();
                  setIsLoading(false);
                })
              ToastService.showToast('success', `${generateAppNameFromType(type)} was disconnected`);
            })
            .catch(error => {
              ToastService.showToast('error', 'Some error occurred! Please try again later!');
              setIsLoading(false);
            });
          setIsLoading(false);
        }
      });
  };
    const actionsJsx = <StyledActionsContainer>
      {/* <Button variant="tertiary" onClick={() => startIntegration()}>Skip this step</Button> */}
      <StyledActionsStepContainer>
          <Button onClick={() => previousStep()} variant="secondary" >Back</Button>
          <Button icon={<>🚀</>} onClick={() => startIntegration()} disabled={!isAnyAppConnected} variant="secondary">Start integration</Button>
      </StyledActionsStepContainer>
    </StyledActionsContainer>
  
  const servicesIntegrationStatus = useMemo(() => {
    return {
      isSlackActive: !!user?.integrations?.slack.active,
      isGoogleDriveActive: !!user?.integrations?.google_drive.active,
      googleDriveAccounts: user?.integrations?.google_drive.accounts,
      isGmailActive: !!user?.integrations?.gmail.active,
      gmailAccounts: user?.integrations?.gmail.accounts,
      isAtlassianActive: !!user?.integrations?.atlassian.active,
      isAsanaActive: !!user?.integrations?.asana.active,
      isGitHubActive: !!user?.integrations?.github.active,
      isClickUpActive: !!user?.integrations?.click_up.active,
      isTrelloActive: !!user?.integrations?.trello.active,
      isFigmaActive: !!user?.integrations?.figma.active,
      isDropboxActive: !!user?.integrations?.dropbox_business.active,
      isTeamsActive: !!user?.integrations?.microsoft_teams.active,
      isAzureMailActive: !!user?.integrations?.outlook.active,
      outlookAccounts: user?.integrations?.outlook.accounts,
      isNotionActive: !!user?.integrations?.notion.active,
      isGoogleCalendarActive: !!user?.integrations?.google_calendar.active,
      googleCalendarAccounts: user?.integrations?.google_calendar.accounts,
      isAtLeastOneServiceConnected:
        user?.integrations?.slack.active ||
        user?.integrations?.google_drive.active ||
        user?.integrations?.gmail.active ||
        user?.integrations?.atlassian.active ||
        user?.integrations?.asana.active ||
        user?.integrations?.github.active ||
        user?.integrations?.click_up.active ||
        user?.integrations?.trello.active ||
        user?.integrations?.figma.active ||
        user?.integrations?.dropbox_business.active ||
        user?.integrations?.microsoft_teams.active ||
        user?.integrations?.outlook.active ||
        user?.integrations?.notion.active ||
        user?.integrations?.google_calendar.active
    }
  }, [user?.integrations]);
  return (
    <>
      <ManageAppsLayout progress={progress} title="Connect your apps" actions={actionsJsx} >
        <LoaderLayout isLoading={isLoading} transparent>
          <StyledAppsWrapper>
            {selectedAllApps.includes(AppTypes.Notion) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isNotionActive}
              icon={'/icons/icon-notion.svg'}
              appName={'Notion'}
              disconnectHandler={disconnectHandler}
              type='notion'
              caption='Pages, Documents, Links, Comments, Tasks, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {(selectedAllApps.includes(AppTypes.Jira) || selectedAllApps.includes(AppTypes.Confluence)) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAtlassianActive}
              icon="/icons/icon-atlassian.png"
              appName='Atlassian (Jira, Confluence)'
              disconnectHandler={disconnectHandler}
              type='atlassian'
              caption='Projects, Spaces, Tasks, Documents, Pages, Links, Comments, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Asana) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAsanaActive}
              icon="/icons/icon-asana.png"
              appName='Asana'
              disconnectHandler={disconnectHandler}
              type='asana'
              caption='Projects, Tasks, Links, Files, Comments, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.ClickUp) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isClickUpActive}
              icon="/icons/icon-click_up.png"
              appName='ClickUp'
              disconnectHandler={disconnectHandler}
              type='click_up'
              caption='Spaces, Tasks, Documents, Links, Files, Comments, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Trello) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isTrelloActive}
              icon="/icons/icon-trello.png"
              appName='Trello'
              disconnectHandler={disconnectHandler}
              type='trello'
              caption='Boards, Tasks, Links, Documents, Files, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.GoogleDrive) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isGoogleDriveActive}
              icon="/icons/icon-drive.png"
              appName='Google Drive'
              disconnectHandler={disconnectHandler}
              type='google_drive'
              caption='Files, Documents'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {(selectedAllApps.includes(AppTypes.MicrosoftTeams) || selectedAllApps.includes(AppTypes.Sharepoint)) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isTeamsActive}
              icon='/icons/icon-microsoft.png'
              appName='Microsoft (Teams, SharePoint)'
              disconnectHandler={disconnectHandler}
              type='microsoft_teams'
              caption='Teams, Discussions, Links, Files, Documents, Comments, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Dropbox) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isDropboxActive}
              icon='/icons/icon_dropbox.png'
              appName='Dropbox for Business'
              disconnectHandler={disconnectHandler}
              type='dropbox_business'
              caption='Files, Documents'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Gmail) && <Integration
              icon={"/icons/icon-gmail.png"}
              servicesIntegrationStatus={servicesIntegrationStatus.isGmailActive}
              appName='Gmail'
              disconnectHandler={disconnectHandler}
              type='gmail'
              caption='Emails, Contacts, Links, Attachments'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Outlook) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isAzureMailActive}
              icon='/icons/icon-outlook.png'
              appName='Outlook'
              disconnectHandler={disconnectHandler}
              accounts={servicesIntegrationStatus.outlookAccounts}
              type='outlook'
              caption='Emails, Events, Contacts, Links, Attachments'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.GoogleCalendar) && <Integration
              icon='/icons/meeting-icon.svg'
              appName='Google Calendar'
              servicesIntegrationStatus={servicesIntegrationStatus.isGoogleCalendarActive}
              disconnectHandler={disconnectHandler}
              type='google_calendar'
              accounts={servicesIntegrationStatus.googleCalendarAccounts}
              caption='Events, Links, Contacts'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Slack) && <Integration
              icon='/icons/icon-slack.png'
              appName='Slack'
              servicesIntegrationStatus={servicesIntegrationStatus.isSlackActive}
              disconnectHandler={disconnectHandler}
              type='slack'
              caption='Channels, Discussions, Links, Files, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Github) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isGitHubActive}
              icon="/icons/icon-github.png"
              appName='GitHub'
              disconnectHandler={disconnectHandler}
              type='github'
              caption='Repositories, Pull requests, Issues, Links, Comments, Files, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
            {selectedAllApps.includes(AppTypes.Figma) && <Integration
              servicesIntegrationStatus={servicesIntegrationStatus.isFigmaActive}
              icon="/icons/icon-figma.png"
              appName='Figma'
              disconnectHandler={disconnectHandler}
              type='figma'
              caption='Designs, People'
              setIsOpenConnectChannels={setIsOpenConnectChannels}
            />}
          </StyledAppsWrapper>
        </LoaderLayout>
        {component}
        <ConnectChannels isOpen={isOpenConnectChannels} setIsOpen={setIsOpenConnectChannels} isOnboarding={true} />
        <StartIntegrationDialog value={isStartIntegrationModalOpen} onChange={setIsStartIntegrationModalOpen} />
      </ManageAppsLayout>
    </>
  );
};
