import { UserType } from "axios/SenseFacade/Users/Types/Get/Get.type";
import { MeContextType } from "./Me.type";

export const defaultUser: UserType = {
  id: '',
  email: '',
  totpEnabled: false,
  organizationId: '',
  paymentPlan: {
    name: '',
    status: '',
    expiresAt: 0,
    seatsNumber: 0
  },
  avatar: '',
  walkThroughCompleted: false,
  googleSsoUserId: null,
  notificationSettings: {
    dailyDigestNotification: false
  },
  firstLogin: false,
  usageMetadata: '',
  integrations: {
    atlassian: {
      active: false,
      inProcess: false,
      accounts: []
    },
    asana: {
      active: false,
      inProcess: false,
      accounts: []
    },
    github: {
      active: false,
      inProcess: false,
    },
    click_up: {
      active: false,
      inProcess: false,
      accounts: []
    },
    trello: {
      active: false,
      inProcess: false,
      accounts: []
    },
    figma: {
      active: false,
      inProcess: false,
      accounts: []
    },
    dropbox_business: {
      active: false,
      inProcess: false,
      accounts: []
    },
    gmail: {
      active: false,
      inProcess: false,
      accounts: []
    },
    google_calendar: {
      active: false,
      inProcess: false,
      accounts: []
    },
    google_drive: {
      active: false,
      inProcess: false,
      accounts: []
    },
    microsoft_teams: {
      active: false,
      inProcess: false,
      accounts: []
    },
    notion: {
      active: false,
      inProcess: false,
      accounts: []
    },
    outlook: {
      active: false,
      inProcess: false,
      accounts: []
    },
    slack: {
      active: false,
      inProcess: false,
      accounts: []
    },
  }
};
export const defaultValue: MeContextType = {
  searchLimits: {
    available: 0,
    limit: 0
  },
  user: null,
  users: [],
  userRelations: [],
  setUsers: () => undefined,
  followedNodes: [],
  follow: () => undefined,
  unfollow: () => undefined,
  isLoggedIn: false,
  login: () => undefined,
  logout: () => undefined,
  setUser: () => undefined,
  loadUser: () => undefined,
  loadUserRelations: () => undefined,
  loadUsers: () => undefined,
  setShouldStartIntegration: () => undefined,
  loadInvitedUsers: () => undefined,
  invitedUsers: [],
  confirmedInvitedUsers: [],
  isIntegrationInProgress: false,
  setShoulShowReloadPageTip: () => undefined,
  getMetadata: () => '',
  setMetadata: () => undefined
};